import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { pickBy, isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useStyle } from './CreateDeliveryPage.app.styles';
import DeliverySupervisor from '../../../features/_App/Delivery/DeliverySupervisor/DeliverySupervisor.js';
import DeliveryLocations from '../../../features/_App/Delivery/DeliveryLocations/DeliveryLocations';
import DeliverySuccessModal from '../../../features/_App/Modal/DeliverySuccessModal';
import { Grid, Paper, Typography } from '@material-ui/core';
import { deliverySeq } from '../../../shared/Constants/app.constant';
import DeliveryLocationsDetail from '../../../features/_App/Delivery/DeliveryLocations/DeliveryLocationsDetail';
import DeliverySupervisorDetails from '../../../features/_App/Delivery/DeliverySupervisor/DeliverySupervisorDetails';
import FormLabel from '../../../shared/Components/_App/FormLabel';
import FormField from '../../../shared/Components/_App/FormField';
import DeliverySchedule from '../../../features/_App/Delivery/DeliverySchedule/DeliverySchedule';
import DeliveryScheduleDetails from '../../../features/_App/Delivery/DeliverySchedule/DeliveryScheduleDetails';
import DeliveryTransporter from '../../../features/_App/Delivery/DeliveryTransorter/DeliveryTransporter';
import DeliveryTranporterDetails from '../../../features/_App/Delivery/DeliveryTransorter/DeliveryTranporterDetails';
import LoadingButton from '../../../shared/Components/_App/LoadingButton/LoadingButton';
import { useTransporter } from '../../../hooks/hooks';
import { DeliveryService } from '../../../features/Delivery/delivery.service';
import { useAsyncEffect, useRegionsMap } from '../../../hooks';
import AppHeader from '../../../layout/AppHeader';
import { toLocalDate } from '../../../shared/Utils/Helper';
import PageView from '../../../shared/Components/_App/PageView';
import { useAccount } from '../../../hooks/useAccount';
import DeliveryUlb from '../../../features/_App/Delivery/DeliveryUlb/DeliveryUlb';
import DeliveryUlbDetails from '../../../features/_App/Delivery/DeliveryUlb/DeliveryUlbDetails';
import { useAuthority } from '../../../shared/Components/HasAuthority/HasAuthorityNew';
import { AUTHORITY2 } from '../../../shared/Constants/Constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
// import { DELIVERY_STATUS } from '../../../shared/Constants/Constants';

const renderStatus = { EDIT: 'edit', VIEW: 'view' };
const CreateDeliveryPage = (props) => {
  const account = useAccount();

  const classes = useStyle();
  const history = useHistory();
  const regions = useRegionsMap();
  const param = history?.location?.state;
  const maxQty = param?.qty - param?.fulfilledQty;
  const { id } = props.match.params;
  const isUpdate = !!id;
  const { hasAuth } = useAuthority();
  const [componentStatus, setComponentStatus] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [deliveryData, setDelivery] = useState();
  const [creationInProgress, setCreationInProgress] = useState(false);
  const [showDeliverySuccess, setShowDeliverySuccess] = useState(false);
  const [editdeliveryId, setEditDeliveryId] = useState();
  const [, setIsEditComponent] = useState();
  const [isWeighBridgeNumberPresent, setIsWeighBridgeNumberPresent] = useState({
    isPickUpWeighBridgePresent: false,
    isDropOffWeighBridgePresent: false,
    pickUpWeighBridgeCheckLoading: false,
    dropOffWeighBridgeCheckLoading: false,
  });
  const [, setIsDeliveryDeleteEditModalVisible] = useState(false);
  const renderSeq = Object.values(deliverySeq);
  const [{ transporterValue }, { dofetchTransporter }] = useTransporter();
  const isPRO = hasAuth([AUTHORITY2.PRO]);

  const deliveryUpdateMaxQty = deliveryData?.assignedToWoItem
    ? deliveryData?.assignedToWoItem?.qty -
      deliveryData?.assignedToWoItem?.fulfilledQty +
      deliveryData?.qty
    : deliveryData?.assignedDeliveries[0]?.assignedToWoItem?.qty -
      deliveryData?.assignedDeliveries[0]?.assignedToWoItem?.fulfilledQty +
      deliveryData?.assignedDeliveries[0]?.qty;

  const assignedWoId = deliveryData?.assignedToWoItem
    ? deliveryData?.assignedToWoItem?.id
    : deliveryData?.assignedDeliveries[0]?.assignedToWoItem?.id;

  const formik = useFormik({
    initialValues: {
      assignedToWoItem: {
        id: param?.id,
      } || {
        id: assignedWoId,
      },
      itemId: param?.itemId || deliveryData?.itemId,
      qty: maxQty || deliveryData?.qty,
      maximumQty: !isEmpty(deliveryData?.assignedDeliveries)
        ? deliveryData?.assignedDeliveries?.[0].assignedToWoItem?.qty
        : deliveryData?.qty,
      sourceName: deliveryData?.deliveryDetails?.sourceName,
      pickUpLocationType: deliveryData?.deliveryDetails?.pickUpLocationType,
      pickUpAddress: deliveryData?.deliveryDetails?.pickUpAddress,
      pickUpPincode: deliveryData?.deliveryDetails?.pickUpPincode,
      pickUpLatitude: deliveryData?.deliveryDetails?.pickUpLatitude,
      pickUpLongitude: deliveryData?.deliveryDetails?.pickUpLongitude,
      pickUpCity: deliveryData?.deliveryDetails?.pickUpCity,
      pickUpState: deliveryData?.deliveryDetails?.pickUpState,
      pickUpLandMark: deliveryData?.deliveryDetails?.pickUpLandMark,
      dropOffAddress: deliveryData?.deliveryDetails?.dropOffAddress,
      dropOffCity: deliveryData?.deliveryDetails?.dropOffCity,
      dropOffState: deliveryData?.deliveryDetails?.dropOffState,
      dropOffPincode: deliveryData?.deliveryDetails?.dropOffPincode,
      dropOffLatitude: deliveryData?.deliveryDetails?.dropOffLatitude,
      dropOffLongitude: deliveryData?.deliveryDetails?.dropOffLongitude,
      pickUpLoadingDate: deliveryData?.deliveryDetails?.pickUpLoadingDate,
      pickUpSupervisorName: deliveryData?.deliveryDetails?.pickUpSupervisorName,
      pickUpSupervisorMobile: deliveryData?.deliveryDetails?.pickUpSupervisorMobile,
      dropSupervisorName: deliveryData?.deliveryDetails?.dropSupervisorName,
      dropSupervisorMobile: deliveryData?.deliveryDetails?.dropSupervisorMobile,
      pickUpVehicleNumber: deliveryData?.deliveryDetails?.pickUpVehicleNumber,
      driverName: deliveryData?.deliveryDetails?.driverName,
      driverMobile: deliveryData?.deliveryDetails?.driverMobile,
      transporterId: deliveryData?.deliveryDetails?.transporterId,
      dropOffRecyclerName: deliveryData?.deliveryDetails?.dropOffRecyclerName,
      vehicleDetails: JSON.parse(deliveryData?.deliveryDetails?.vehicleDetails || '{}'),
      ulbState: deliveryData?.deliveryDetails?.ulbState,
      ulbDistrict: deliveryData?.deliveryDetails?.ulbDistrict,
      ulbName: deliveryData?.deliveryDetails?.ulbName,
      ulbId: deliveryData?.deliveryDetails?.ulbId,
      ulbAddress: deliveryData?.deliveryDetails?.ulbAddress,
      ulbPincode: deliveryData?.deliveryDetails?.ulbPincode,
      recyclerId: deliveryData?.deliveryDetails?.recyclerId,
      dropOffTareWeight: deliveryData?.deliveryDetails?.dropOffTareWeight || null,
      dropOffNetWeight: deliveryData?.deliveryDetails?.dropOffNetWeight || null,
      dropOffWeighBridgeNumber: deliveryData?.deliveryDetails?.dropOffWeighBridgeNumber,
      dropOffGrossWeight: deliveryData?.deliveryDetails?.dropOffGrossWeight || null,
      pickUpGrossWeight: deliveryData?.deliveryDetails?.pickUpGrossWeight || null,
      pickUpTareWeight: deliveryData?.deliveryDetails?.pickUpTareWeight || null,
      pickUpNetWeight: deliveryData?.deliveryDetails?.pickUpNetWeight || null,
      pickUpWeighBridgeNumber: deliveryData?.deliveryDetails?.pickUpWeighBridgeNumber,
      pickUpLrNumber: deliveryData?.deliveryDetails?.pickUpLrNumber,
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema: (props) => {
      return Yup.lazy((values) => {
        return Yup.object().shape({
          qty: Yup.number()
            .max(maxQty || deliveryUpdateMaxQty, 'Quantity should be <= Need of WorkOrder')
            .min(1, 'Quantity should be greater than or equal to Allocated Quantity')
            .required('Enter Qty'),
          pickUpLocationType: Yup.string().required(),
          pickUpAddress: Yup.string().required(),
          pickUpPincode: Yup.number().required(),
          pickUpCity: Yup.string().required(),
          pickUpState: Yup.string().required(),
          pickUpLoadingDate: Yup.string().required(),
          sourceName: Yup.string().required(),
          ulbAddress: Yup.string(),
          ulbDistrict: Yup.string(),
          ulbState: Yup.string(),
          ulbName: Yup.string(),
          dropOffCity: Yup.string().required(),
          dropOffState: Yup.string().required(),
          // pickUpGrossWeight: Yup.number().required(),
          // pickUpTareWeight: Yup.number().required(),
          pickUpNetWeight: Yup.number().required(),
          pickUpWeighBridgeNumber: Yup.string().required(),
          pickUpLrNumber: Yup.string().required(),
        });
      });
    },
    onSubmit: (values, actions) => {
      // if (
      //   values?.pickUpState !== values.ulbState &&
      //   !isEmpty({
      //     ulbAddress: Yup.string(),
      //     ulbDistrict: Yup.string(),
      //     ulbState: Yup.string(),
      //     ulbName: Yup.string(),
      //   })
      // ) {
      //   toast.error('Source and ULB Location should be same');
      //   return;
      // }
      setEditDeliveryId('');
      values.vehicleDetails = JSON.stringify(values.vehicleDetails);
      values.pickUpVehicleNumber =
        values?.pickUpVehicleNumber && values?.pickUpVehicleNumber?.replace(/\s/g, '');
      const deliveryDetails = { ...deliveryData?.deliveryDetails, ...values };
      delete deliveryDetails.itemId;
      delete deliveryDetails.qty;
      delete deliveryDetails.assignedToWoItem;
      const { itemId, qty, assignedToWoItem } = { ...values };
      doCreateAndupdateDelivery(
        pickBy({
          itemId,
          qty,
          assignedToWoItem,
          deliveryDetails,
        })
      );
    },
  });

  useAsyncEffect(async () => {
    if (isUpdate) {
      await doLoadDelivery(id);
    }
  }, [id]);

  useAsyncEffect(async () => {
    const response = await dofetchTransporter();
  }, []);

  useEffect(() => {
    if (deliveryData) {
      const details = deliveryData?.deliveryDetails;
      const supervisorDataExists =
        isEmpty(details.pickUpSupervisorName) || isEmpty(details.dropSupervisorName);
      const transporterDataExists = !isEmpty(details.pickUpVehicleNumber);
      // ||
      // !isEmpty(details.transporterId) ||
      // !isEmpty(details.driverName);
      const _status = {
        ...componentStatus,
        [deliverySeq.LOCATION]: renderStatus.VIEW,
        [deliverySeq.ULB]: renderStatus.VIEW,
        [deliverySeq.SCHEDULE]: renderStatus.VIEW,
        [deliverySeq.DOCUMENTS]: renderStatus.EDIT,
      };
      // if supervisor details exists show view else show edit
      if (supervisorDataExists) {
        _status[deliverySeq.SUPERVISOR] = renderStatus.VIEW;
        if (!transporterDataExists) {
          _status[deliverySeq.TRANSPORTER] = renderStatus.EDIT;
        }
      } else {
        _status[deliverySeq.SUPERVISOR] = renderStatus.EDIT;
      }
      // if tranporter details exists show view

      if (transporterDataExists) {
        _status[deliverySeq.TRANSPORTER] = renderStatus.VIEW;
      }

      if (supervisorDataExists && transporterDataExists) {
        _status[deliverySeq.SUPERVISOR] = renderStatus.VIEW;
        _status[deliverySeq.TRANSPORTER] = renderStatus.EDIT;
      }

      if (supervisorDataExists && transporterDataExists) {
        _status[deliverySeq.SUPERVISOR] = renderStatus.VIEW;
        _status[deliverySeq.TRANSPORTER] = renderStatus.VIEW;
      }
      setComponentStatus(_status);
    } else {
      const _status = {
        ...componentStatus,
        [deliverySeq.LOCATION]: renderStatus.EDIT,
        [deliverySeq.ULB]: renderStatus.EDIT,
        [deliverySeq.SCHEDULE]: renderStatus.EDIT,
      };
      setComponentStatus(_status);
    }
  }, [deliveryData]);

  useEffect(() => {
    setIsEditComponent(getKeyByValue(componentStatus, 'edit'));
  }, [componentStatus]);

  function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }

  const displayMyself = (userMobile, selectedMobile, selectedName) => {
    return userMobile === selectedMobile ? 'Myself' : selectedName;
  };

  const showEditChange = (type) => {
    const _status = {
      ...componentStatus,
    };
    if (type === 'location') {
      _status[deliverySeq.LOCATION] = renderStatus.EDIT;
    } else if (type === 'ulb') {
      _status[deliverySeq.ULB] = renderStatus.EDIT;
    } else if (type === 'schedule') {
      _status[deliverySeq.SCHEDULE] = renderStatus.EDIT;
    } else if (type === 'supervisor') {
      _status[deliverySeq.SUPERVISOR] = renderStatus.EDIT;
    } else if (type === 'transporter') {
      _status[deliverySeq.TRANSPORTER] = renderStatus.EDIT;
    }
    setComponentStatus(_status);
  };

  const components = {
    [deliverySeq.LOCATION]: {
      edit: (
        <DeliveryLocations
          formik={formik}
          district={deliveryData?.districtId || param?.districtId}
          state={deliveryData?.state || regions[param?.stateId]?.name}
          isWeighBridgeNumberPresent={isWeighBridgeNumberPresent}
          setIsWeighBridgeNumberPresent={setIsWeighBridgeNumberPresent}
          deliveryData={deliveryData}
          maxQty={maxQty}
        />
      ),
      view: (
        <DeliveryLocationsDetail
          isEditable={true}
          data={formik.values}
          showEditChange={() => showEditChange('location')}
          isPRO={isPRO}
        />
      ),
    },
    [deliverySeq.ULB]: {
      edit: <DeliveryUlb createDeliveryForm={formik} />,
      view: (
        <DeliveryUlbDetails
          isEditable={true}
          data={formik.values}
          showEditChange={() => showEditChange('ulb')}
          isPRO={isPRO}
        />
      ),
    },
    [deliverySeq.SCHEDULE]: {
      edit: <DeliverySchedule createSchedule={formik} />,
      view: (
        <DeliveryScheduleDetails
          data={formik.values}
          showEditChange={() => showEditChange('schedule')}
          isPRO={isPRO}
        />
      ),
    },
    [deliverySeq.SUPERVISOR]: {
      edit: (
        <DeliverySupervisor
          createDeliveryForm={formik}
          user={account.user}
          displayMyself={displayMyself}
          isPRO={isPRO}
        />
      ),
      view: (
        <DeliverySupervisorDetails
          isEditable={true}
          data={formik.values}
          showEditChange={() => showEditChange('supervisor')}
          user={account.user}
          displayMyself={displayMyself}
          isPRO={isPRO}
        />
      ),
    },
    [deliverySeq.TRANSPORTER]: {
      edit: (
        <DeliveryTransporter
          createTrasnporterForm={formik}
          dofetchTransporter={dofetchTransporter}
          transporterValue={transporterValue}
          isPRO={isPRO}
        />
      ),
      view: (
        <DeliveryTranporterDetails
          isEditable={true}
          data={formik.values}
          showEditChange={() => showEditChange('transporter')}
          transporterValue={transporterValue}
          isPRO={isPRO}
        />
      ),
    },
  };

  const redirectToUpdateDelivery = () => {
    history.push(`/update-delivery/${editdeliveryId}?isCreated=created`);
  };

  /**
   * This api call is used to load delivery details based on id
   * @param {*} id
   * @return {Promise<*>}
   */
  const doLoadDelivery = async (id) => {
    setLoading(true);
    try {
      const response = await DeliveryService.fetchDeliveryById(id);
      if (response === 400) {
        if (isPRO) history.push('/deliveries-list');
        if (!isPRO) history.push('/tabs/open-deliveries?tab=true');
      } else setDelivery(response?.data);
      setLoading(false);
    } catch (error) {
      console.log(error?.response?.data);
    }
  };

  /**
   * This will be called while creating and updating the delivery
   * @param data
   */
  const doCreateAndupdateDelivery = (data) => {
    setCreationInProgress(true);
    if (isUpdate) {
      const updateDelivery = {
        ...deliveryData,
        ...data,
        deliveryDate: data.deliveryDetails.pickUpLoadingDate,
        stateId: data.deliveryDetails.pickUpState,
        districtId: data.deliveryDetails.pickUpCity,
      };
      doUpdateDelivery(updateDelivery);
    } else {
      const createDelivery = {
        ...data,
        deliveryDate: data.deliveryDetails.pickUpLoadingDate,
        stateId: data.deliveryDetails.pickUpState,
        districtId: data.deliveryDetails.pickUpCity,
        createAssignment: true,
      };
      doCreateDelivery(createDelivery);
    }
  };
  /**
   * This api call is used to create delivery
   * @param {*} deliveryCreatePayload
   * @return {Promise<*>}
   */
  const doCreateDelivery = async (deliveryCreatePayload) => {
    try {
      const deliveryRes = await DeliveryService.createDelivery(deliveryCreatePayload);
      if (deliveryRes?.status === 201) {
        setCreationInProgress(false);
        setShowDeliverySuccess(true);
        setEditDeliveryId(deliveryRes?.data?.id);
      } else setCreationInProgress(false);
    } catch (error) {
      setCreationInProgress(false);
    }
  };

  /**
   * This api call is used to update delivery
   * @param {*} deliveryUpdatePayload
   * @return {Promise<*>}
   */
  const doUpdateDelivery = async (deliveryUpdatePayload) => {
    try {
      const deliveryRes = await DeliveryService.updateDelivery(deliveryUpdatePayload);
      setCreationInProgress(false);
      const deliveryDetailsData = deliveryRes?.data.deliveryDetails;
      if (
        // deliveryDetailsData.pickUpSupervisorMobile &&
        // deliveryDetailsData.dropSupervisorMobile &&
        // deliveryDetailsData.transporterId &&
        // deliveryDetailsData.driverName &&
        deliveryDetailsData.pickUpVehicleNumber
      ) {
        history.push(`/delivery-view/${id}`);
      } else {
        setDelivery(deliveryRes?.data);
      }
      toast.success('Delivery Updated Successfully');
    } catch (error) {
      setCreationInProgress(false);
    }
  };

  const displayWoId = deliveryData?.assignedToWoItem
    ? deliveryData?.assignedToWoItem?.workOrderId
    : deliveryData?.assignedDeliveries[0]?.assignedToWoItem?.workOrderId;

  if (isLoading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '30%' }}>
        <CircularProgress />
      </div>
    );
  }

  // const transporterName =
  //   transporterValue?.find((e) => e.id === formik.values.transporterId)?.name || '';
  // const isPickupDropSupervisorPresent = isEmpty(
  //   formik.values.pickUpSupervisorName && formik.values.dropSupervisorName
  // );
  // const isTransporterDriverPresent = isEmpty(formik.values.driverName && transporterName);
  const isTransporterSupervisorPresent = isEmpty(formik.values.pickUpVehicleNumber);
  // const isQuantityDisabled =
  //   deliveryData?.assignedDeliveries &&
  //   (deliveryData?.assignedDeliveries[0]?.status === DELIVERY_STATUS.IN_PROGRESS.id ||
  //     deliveryData?.assignedDeliveries[0]?.status === DELIVERY_STATUS.IN_TRANSIT.id);

  const isCreateButtonEnabled =
    creationInProgress ||
    !formik.isValid ||
    !formik.dirty ||
    isWeighBridgeNumberPresent?.pickUpWeighBridgeCheckLoading ||
    isWeighBridgeNumberPresent?.dropOffWeighBridgeCheckLoading ||
    formik?.values?.pickUpTareWeight === null ||
    formik?.values?.pickUpGrossWeight === null;

  const isSaveButtonEnabled =
    creationInProgress ||
    !formik.isValid ||
    isTransporterSupervisorPresent ||
    isWeighBridgeNumberPresent?.pickUpWeighBridgeCheckLoading ||
    isWeighBridgeNumberPresent?.dropOffWeighBridgeCheckLoading ||
    formik?.values?.pickUpTareWeight === null ||
    formik?.values?.pickUpGrossWeight === null ||
    formik?.values?.pickUpNetWeight?.toString()?.includes('-') ||
    formik?.values?.pickUpNetWeight === 0;

  let isQtyDiffered =
    formik.values.dropOffNetWeight > 0 && formik.values.dropOffNetWeight < formik.values.qty;

  return (
    <Box className={!isPRO ? classes.deliveryMain : classes.proDeliveryMain}>
      {isUpdate && (
        <AppHeader
          titleRender={(route) => (
            <div className={classes.headerText}>
              <Typography variant={'subtitle2'} noWrap>
                #{id}
              </Typography>
              <span className={'text-small text-muted font-regular text-uppercase'}>
                Created on {toLocalDate(deliveryData?.createdOn, 'DD-MMM-YYYY hh:mm a')}
              </span>
            </div>
          )}
          rightRender={(route) => (
            <>
              <div>
                <FontAwesomeIcon
                  size="sm"
                  icon={faEllipsisV}
                  onClick={() =>
                    !isPRO &&
                    !isEmpty(deliveryData?.deliveryDetails?.pickUpVehicleNumber) &&
                    setIsDeliveryDeleteEditModalVisible(true)
                  }
                  style={{ fontWeight: 'bold', color: '#2E3748', fontSize: '18px' }}
                />
              </div>
            </>
          )}
        />
      )}
      <PageView loading={isLoading}>
        <Paper elevation={0} className="px-4 pt-3 mb-4">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormLabel label={'Work Order'}></FormLabel>
              <Typography variant={'subtitle2'}>
                {param?.workOrderId || displayWoId || 'NA'}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <FormLabel label={'Item'}></FormLabel>
              <Typography variant={'subtitle2'}>{param?.itemName || deliveryData?.item}</Typography>
            </Grid>
            <Grid item xs={6}>
              <FormLabel label={'State'}></FormLabel>
              <Typography variant={'subtitle2'}>
                {regions[param?.stateId]?.name || deliveryData?.state}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <FormLabel label={'District'}></FormLabel>
              <Typography variant={'subtitle2'}>
                {regions[param?.stateId]?.districts[param?.districtId]?.name ||
                  deliveryData?.district}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormField
                disabled={true}
                FormControlClassName="mb-0"
                label="Approx. Quantity (In KG)"
                type="number"
                value={formik.values.qty}
                error={formik.errors?.qty}
                isValid={!formik.errors?.qty && formik.dirty}
                onChange={formik.handleChange('qty')}
                onBlur={formik.handleBlur('qty')}
                touched={formik.touched?.qty}
              />
              <Typography color="error" variant="body1">
                {formik.errors.qty}
              </Typography>
              {isQtyDiffered && (
                <Typography style={{ color: '#855f2d' }} variant="body1">
                  Inward net weight quantity is less than Final quantity
                </Typography>
              )}
            </Grid>
          </Grid>
        </Paper>
        {renderSeq.map((compKey) => {
          const component = componentStatus[compKey]
            ? components[compKey][componentStatus[compKey]]
            : null;
          return component;
        })}
        {!isPRO && (
          <LoadingButton
            className={classes.submitBottom}
            loading={creationInProgress}
            loadingText={'Loading..'}
            disabled={id ? isSaveButtonEnabled : isCreateButtonEnabled}
            onClick={() => formik.handleSubmit()}>
            {id ? 'Save & Continue' : 'Create Delivery'}
          </LoadingButton>
        )}
        <DeliverySuccessModal
          open={showDeliverySuccess}
          onClose={() => history.push('/tabs/dashboard')}
          onSelect={redirectToUpdateDelivery}
        />
        {/* {isDeliveryDeleteEditModalVisible && (
          <DeliveryEditAndDeleteOptionModal
            setIsDeliveryDeleteEditModalVisible={setIsDeliveryDeleteEditModalVisible}
            isDeliveryDeleteEditModalVisible={isDeliveryDeleteEditModalVisible}
            isPRO={isPRO}
            id={id}
            showEdit={false}
            DeliveryService={DeliveryService}
            status={deliveryData?.assignedDeliveries[0]?.status}
            deliveryDocuments={deliveryData?.deliveryDocuments}
          />
        )} */}
      </PageView>
    </Box>
  );
};

export default CreateDeliveryPage;
