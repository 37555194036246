import API from '../../shared/Config/Api';
import ApiEndpoints from '../../shared/Config/ApiEndpoints.const';

const getPermissionsForRole = async (role) => {
  const response = API.get(ApiEndpoints.GET_ALL_PERMISSIONS_FOR_ROLE + '/' + role);
  return response;
};

export const permissionsAPI = {
  getPermissionsForRole,
};
