import { useSelector } from 'react-redux';
import { groupBy, mapValues } from 'lodash';

export const usePermissions = () =>
  useSelector(({ permissions }) => {
    const formattedPermissions = permissions?.permissions?.permissions?.map(
      ({ groupId, groupName, moduleName, name, hasAccess }) => ({
        groupId,
        formattedGroupName: groupName?.replace(' ', '_'),
        formattedModuleName: moduleName?.replace(' ', '_'),
        name,
        hasAccess,
      })
    );

    return mapValues(groupBy(formattedPermissions, 'formattedGroupName'), (group) =>
      mapValues(groupBy(group, 'formattedModuleName'), (module) => groupBy(module, 'name'))
    );
  });

export const usePermissionsStatus = () =>
  useSelector(({ permissions }) => {
    return permissions.status;
  });
