import React, { useState, useEffect } from 'react';
import { Container, Grid, CircularProgress } from '@material-ui/core';
import { ReactComponent as CreateIcon } from '../../../assets/img/_App/create.svg';
import { ReactComponent as EyeIcon } from '../../../assets/img/_App/eye.svg';
import { ReactComponent as IntransitIcon } from '../../../assets/img/_App/in_transit.svg';
import { ReactComponent as ScheduleIcon } from '../../../assets/img/_App/schedule.svg';
import ActiveCard from '../../../features/_App/Home/ActiveCard';
// import GraphCard from '../../../features/_App/Home/GraphCard';
import { makeStyles } from '@material-ui/core/styles';
// import Map from '../../../shared/Components/_App/GoogleMap/Map';
import HomeHeader from '../../../features/_App/Home/HomeHeader';
import { useFinancialYear } from '../../../hooks/useFinancialYear';
import { useAccount, useAsyncEffect } from '../../../hooks';
import { WorkOrderService } from '../../../features/WorkOrder/workOrder.service';
import { groupBy, isEmpty, sumBy } from 'lodash';
import { DeliveryService } from '../../../features/Delivery/delivery.service';
import { AUTHORITY2, DELIVERY_STATUS } from '../../../shared/Constants/Constants';
import { roundOffAmount } from '../../../shared/Utils/Utils';
import { useAuthority } from '../../../shared/Components/HasAuthority/HasAuthorityNew';
import { Redirect } from 'react-router-dom';
import { appMaterialItems } from '../../../shared/Constants/app.constant';
// import { useSelector } from 'react-redux';

export const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
}));
const HomePage = () => {
  const classes = useStyles();
  const { selectedFinancialYear } = useFinancialYear();
  // const { selectedFinancialYear, financialYears } = useSelector(({ account }) => {
  //   return {
  //     selectedFinancialYear: account?.selectedFinancialYear,
  //     financialYears: account?.financialYears,
  //   };
  // });

  const { myCustomerId } = useAccount();
  const [workOrderQty, setWorkOrderQty] = useState();
  const [requireAttentionDeliveriesCount, setRequireAttentionDeliveriesCount] = useState(0);
  const [intransitDeliveries, setIntransitDeliveries] = useState([]);
  const [plannedDeliveries, setPlannedDeliveries] = useState([]);
  const [workOrderQtyLoading, setWorkOrderQtyLoading] = useState(true);
  const [requireAttentionDeliverieLoading, setRequireAttentionDeliverieLoading] = useState(true);
  const [inTransitPlannedDeliveriesLoading, setInTransitPlannedDeliveriesLoading] = useState(true);

  const [filter, setFilter] = useState({
    size: 500,
    page: 0,
    targetFromDate: selectedFinancialYear?.fromDate,
    targetToDate: selectedFinancialYear?.toDate,
    itemIds: appMaterialItems,
    'isNeeded.equals': true,
    'toCustomerId.equals': myCustomerId,
  });
  const [deliveryFilter, setDeliveryFilter] = useState({
    deliveryDateFrom: selectedFinancialYear?.fromDate,
    deliveryDateTo: selectedFinancialYear?.toDate,
    sort: 'createdOn,desc',
    direction: 'RECEIVED',
    itemIds: appMaterialItems,
    size: 1000,
  });
  const { hasAuth } = useAuthority();
  const isPRO = hasAuth([AUTHORITY2.PRO]);

  useAsyncEffect(async () => {
    await getAllWorkOrders({ ...filter });
  }, [selectedFinancialYear]);

  useAsyncEffect(async () => {
    await getRequireAttentionCount({
      ...deliveryFilter,
      assignedStatus: 'IN_PROGRESS,DISPUTED',
      'requiresAttention.equals': true,
    });
  }, [selectedFinancialYear]);

  useAsyncEffect(async () => {
    await getRequisitionDeliveries({
      ...deliveryFilter,
      direction: 'ASSIGNED',
      status: 'IN_PROGRESS,IN_TRANSIT',
    });
  }, [selectedFinancialYear]);

  const setUpdatedFinancialYear = (financialYear) => {
    setFilter({
      ...filter,
      targetFromDate: financialYear?.fromDate,
      targetToDate: financialYear?.toDate,
    });
    setDeliveryFilter({
      ...deliveryFilter,
      deliveryDateFrom: financialYear?.fromDate,
      deliveryDateTo: financialYear?.toDate,
    });
  };

  const modifyWorkOrderData = (woData) => {
    let setArray = [];
    let woStateGrouping = groupBy(woData, function (value) {
      return value?.stateId;
    });
    Object.keys(woStateGrouping).map((list) => {
      const childs = woStateGrouping[list];
      if (list !== 'null') {
        setArray.push({
          stateId: list,
          qty: sumBy(childs, 'qty') - sumBy(childs, 'fulfilledQty'),
        });
      }
      setWorkOrderQty(sumBy(setArray, 'qty'));
    });
    setWorkOrderQtyLoading(false);
  };

  const getAllWorkOrders = async (params) => {
    try {
      const response = await WorkOrderService.fetchWorkOrderItems(params);
      if (response?.data) {
        modifyWorkOrderData(response?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getRequisitionDeliveries = async (params) => {
    try {
      const response = await DeliveryService.fetchDeliveries(params);
      if (response?.deliveries) {
        setIntransitDeliveries(
          response?.deliveries?.assigned.filter((e) => e.status === DELIVERY_STATUS.IN_TRANSIT.id)
        );
        setPlannedDeliveries(
          response?.deliveries?.assigned.filter((e) => e.status === DELIVERY_STATUS.IN_PROGRESS.id)
        );
        setInTransitPlannedDeliveriesLoading(false);
      }
    } catch (e) {}
  };

  if (isPRO) {
    return <Redirect to={'/deliveries-list'} />;
  }

  const getRequireAttentionCount = async (params) => {
    try {
      const response = await DeliveryService.fetchRequireAttentionCount(params);
      if (response?.data) setRequireAttentionDeliveriesCount(response?.data);
      else setRequireAttentionDeliveriesCount(0);
      setRequireAttentionDeliverieLoading(false);
    } catch (e) {}
  };

  return (
    <Container className="HomePage pt-4">
      <HomeHeader setUpdatedFinancialYear={setUpdatedFinancialYear} />
      <Grid container spacing={1}>
        <Grid item md={3} xs={6} md={6} sm={6}>
          <ActiveCard
            cardName="Quantity to be Fulfilled (MT)"
            qty={
              workOrderQtyLoading ? (
                <CircularProgress size={20} />
              ) : (
                roundOffAmount(workOrderQty || 0) / 1000
              )
            }
            to={'/wo-state-list'}
            child={<CreateIcon />}
            bgColor="#eff5ff"
          />
        </Grid>
        <Grid item md={3} xs={6} md={6} sm={6}>
          <ActiveCard
            to={'/require-attention-list'}
            cardName="Require your Attention "
            qty={
              requireAttentionDeliverieLoading ? (
                <CircularProgress size={20} />
              ) : (
                requireAttentionDeliveriesCount
              )
            }
            child={<EyeIcon />}
            bgColor="#fff5f5"
          />
        </Grid>
        <Grid item md={3} xs={6} md={6} sm={6}>
          <ActiveCard
            cardName="In Transit Deliveries"
            to={'/tabs/intransit-deliveries'}
            qty={
              inTransitPlannedDeliveriesLoading ? (
                <CircularProgress size={20} />
              ) : (
                intransitDeliveries?.length
              )
            }
            child={<IntransitIcon />}
            bgColor="#fcf4fb"
          />
        </Grid>
        <Grid item md={3} xs={6} md={6} sm={6}>
          <ActiveCard
            cardName="Planed Deliveries"
            to={'/tabs/planned-deliveries'}
            qty={
              inTransitPlannedDeliveriesLoading ? (
                <CircularProgress size={20} />
              ) : (
                plannedDeliveries?.length
              )
            }
            child={<ScheduleIcon />}
            bgColor="#f0ffed"
          />
        </Grid>
        {/* <Grid item xs={12} sm={12} md={12}>
          {!isEmpty(selectedFinancialYear && selectedFinancialYear) && (
            <GraphCard
              graphName="PROGRESS VS TARGET"
              selectedFinancialYear={selectedFinancialYear}
              financialYears={financialYears}
              selectedFinancialYear={selectedFinancialYear}
            />
          )}
        </Grid> */}
      </Grid>
    </Container>
  );
};

export default HomePage;
