import { createSlice } from '@reduxjs/toolkit';
import { getAllPermissions } from './allPermissionsThunks';

const initialState = {
  permissions: [],
};

export const AllPermissionsSlice = createSlice({
  name: 'allPermissions',
  initialState,
  reducers: {
    setAllPermissions: (state, action) => {
      state.permissions = action.permissions;
    },
  },
  extraReducers: {
    [getAllPermissions.pending]: (state) => {
      state.isLoading = true;
      state.error = false;
    },
    [getAllPermissions.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.permissions = action.payload;
      state.status = action.payload;
      state.error = false;
    },
    [getAllPermissions.rejected]: (state) => {
      state.isLoading = false;
      state.error = 500;
    },
  },
});

export const { setAllPermissions } = AllPermissionsSlice.actions;
export const permissions = (state) => state.permissions;
export default AllPermissionsSlice.reducer;
