import API from '../../shared/Config/Api';
import ApiEndpoints from '../../shared/Config/ApiEndpoints.const';
import APIEndpoints from '../../shared/Config/ApiEndpoints.const';

const editAssignedQuantity = async (assignmentDetails) => {
  return await API.put(APIEndpoints.ASSIGN_DELIVERY, assignmentDetails);
};

const fetchSourceAddresses = async () => {
  return await API.get(APIEndpoints.GET_SOURCE_ADDRESSES);
};

const fetchRecyclerAddresses = async () => {
  return await API.get(APIEndpoints.GET_RECYCLER_ADDRESSES);
};

const fetchRecyclerFromMarketPlace = async (params) => {
  return await API.get(APIEndpoints.GET_RECYCLER_FROM_MP, null, params);
};

const fetchDeliveries = async (params) => {
  const response = await API.get(APIEndpoints.DELIVERIES, null, params);
  const totalCount = Number(response?.headers['x-total-count']);
  return { totalCount, deliveries: response.data };
};

const fetchRequireAttentionCount = async (params) => {
  const response = await API.get(APIEndpoints.DELIVERIES + '/count', null, params);
  return response;
};

const fetchDeliveryById = async (deliveryID) => {
  return await API.get(APIEndpoints.DELIVERIES + `/${deliveryID}`);
};

const deleteDeliveryById = async (deliveryId) => {
  return await API.delete(APIEndpoints.DELIVERIES + '/' + deliveryId);
};

const approveDeliveryByParams = async (params) => {
  return await API.put(APIEndpoints.APPROVE_DELIVERY, null, params);
};

const uploadDeliveryDocument = async (payload) => {
  return await API.post(APIEndpoints.DELIVERIES_UPLOAD_DOC, payload);
};

const archiveDeliveryDocuments = async (params) => {
  return await API.put(APIEndpoints.DELIVERY_DOCS_ARCHIVE, null, params);
};

const updateDeliveryDocument = async (payload) => {
  return await API.put(APIEndpoints.DELIVERIES_UPLOAD_DOC, payload);
};

const deleteDeliveryDocument = async (documentId) => {
  return await API.delete(APIEndpoints.DELIVERIES_UPLOAD_DOC + `/${documentId}`);
};

const updateDocumentType = async (payload) => {
  return await API.put(APIEndpoints.DELIVERIES_CHANGE_TYPE, payload);
};

const updateDelivery = async (deliveryDetails) => {
  return await API.put(APIEndpoints.DELIVERIES, deliveryDetails);
};

const qtySubmitForApproval = async (deliveryDetails) => {
  return await API.post(APIEndpoints.QTY_SUBMIT_FOR_APPROVAL, deliveryDetails);
};

const createDelivery = async (deliveryDetails) => {
  return await API.post(APIEndpoints.DELIVERIES, deliveryDetails);
};

const fetchTickets = async (deliveryID) => {
  return await API.get(APIEndpoints.TICKETS + '?deliveryIds=' + deliveryID);
};

const raiseTicket = async (payload) => {
  return await API.post(APIEndpoints.TICKETS, payload);
};

const replyTicket = async (payload) => {
  return await API.post(APIEndpoints.TICKETS + `/${payload.ticketId}/comments`, payload);
};

const uploadCompressedDocument = async (docDetails) => {
  return await API.post(APIEndpoints.DOCUMENT_ATTACH, docDetails);
};

const checkWeighBridgeNumber = async (docType, wbNumber) => {
  return await API.get(APIEndpoints.WEIGHBRIDGE_CHECK + docType + '&wbNumber=' + wbNumber);
};

const assignRecyclersToSP = async (payload) => {
  return await API.post(APIEndpoints.MAP_RECYCLER_TO_SP, payload);
};

const fetchSPRecyclers = async (spId) => {
  return await API.get(APIEndpoints.GET_SP_RECYCLERS + spId);
};

const approveDeliveryDoc = async (payload) => {
  return await API.put(APIEndpoints.APPROVE_DOCUMENT, payload);
};

const rejectDocument = async (payload) => {
  return await API.post(APIEndpoints.TICKETS, payload);
};

const approveDocument = async (payload) => {
  return await API.put(APIEndpoints.APPROVE_DOCUMENT, payload);
};

const approveAllocation = async (payload) => {
  return await API.post(APIEndpoints.APPROVE_ALLOCATION, payload);
};

const getAllUlbs = async (payload) => {
  return await API.get(APIEndpoints.ULB_LITE).data;
};
const generateEwaybillEinvoice = async (payload) => {
  return await API.post(APIEndpoints.GENERATE_EWAYBILL_EINVOICE + 'id=' + payload.id);
};

const generateInvoice = async (payload) => {
  return await API.post(APIEndpoints.GENERATE_INVOICE + 'id=' + payload.id);
};

const viewInvoice = async (payload) => {
  return await API.get(APIEndpoints.VIEW_INVOICE + 'id=' + payload.id);
};

const markStatusToIntransit = async (payload) => {
  return await API.put(APIEndpoints.STATUS_TO_INTRANSIT, payload);
};

const sendPickUpspSms = async (payload) => {
  return await API.post(APIEndpoints.SEND_PICKUP_SUPERVISOR_SMS, payload);
};

const sendDropspSms = async (payload) => {
  return await API.post(APIEndpoints.SEND_DROP_SUPERVISOR_SMS, payload);
};

const doSendSMSToDriver = async (payload) => {
  return await API.post(APIEndpoints.SEND_SMSTO_DRIVER, payload);
};

const fetchEWasteBrands = async (payload) => {
  return await API.get(APIEndpoints.EWASTE_BRANDS + payload);
};

const fetchBrandDetails = async (payload) => {
  return await API.get(APIEndpoints.BRAND_DETAILS + payload);
};

const auditDeliveryDetails = async (payload) => {
  return await API.get(APIEndpoints.DELIVERY_DETAILS + payload);
};

const getUsersDetails = async () => {
  return await API.get(APIEndpoints.GET_USER);
};

const getUsersDetailsV2 = async () => {
  return await API.get(APIEndpoints.GET_USERV2);
};

const getDocumentList = async (param) => {
  if (param === undefined) {
    return await API.get(APIEndpoints.GET_ALL_DOC_TYPES);
  }
  return await API.get(APIEndpoints.GET_ALL_DOC_TYPES + param);
};

const deletePWADeliveryById = async (deliveryId) => {
  return await API.delete(APIEndpoints.DELETE_PWA_DELIVERY + '/' + deliveryId);
};

const doBulkUpload = async (payload) => {
  return await API.post(APIEndpoints.DELIVERY_BULK_UPLOAD, payload);
};

const createMarketPlaceWorkOrder = async (payload) => {
  return await API.post(APIEndpoints.CREATE_MARKETPLACE_WORK_ORDER, payload);
};

const getPVCreationRequest = async (deliveryId) => {
  return (await API.get(APIEndpoints.AGGREGATOR_PV_CREATION_REQUEST, null, { deliveryId })).data;
};

const createAggregatorPV = async (deliveryId) => {
  return await API.post(APIEndpoints.CREATE_AGGREGATOR_PV, null, null, { deliveryId });
};

const getAllDocTypes = async (payload) => {
  return await API.get(APIEndpoints.GET_ALL_DOC_TYPES, payload);
};

const updateStatus = async (payload) => {
  return API.put(APIEndpoints.UPDATE_BRAND_DELIVERY_STATUS, null, payload);
};

const updateSPDeliveryStatus = async (payload) => {
  return API.put(APIEndpoints.UPDATE_SP_LOGIN_DELIVERY_STATUS, null, payload);
};

const autoAllocation = async (payload) => {
  return await API.put(APIEndpoints.AUTO_ALLOCATION, payload);
};

const fetchDeliveryByIdForSuperUser = async (deliveryID) => {
  return await API.get(APIEndpoints.SUPERUSERDELIVERIES + `/${deliveryID}`);
};

const addCertificateDate = async (payload) => {
  return await API.put(APIEndpoints.ADD_CERTIFICATE_DATE, payload);
};

const generateSalvageInvoice = async (deliveryId) => {
  return await API.post(APIEndpoints.GENERATE_SALVAGE_INVOICE + `deliveryId=${deliveryId}`);
};

const updateSalvagePrice = async ({ deliveryId, serviceProviderRate }) => {
  return await API.put(
    APIEndpoints.UPDATE_SALVAGE_PRICE +
      `deliveryId=${deliveryId}&serviceProviderRate=${serviceProviderRate}`
  );
};

const sendSubscription = async (payload) => {
  return await API.post(APIEndpoints.SEND_SUBSCRIPTION, payload);
};

const endSubscription = async (deliveryId) => {
  return await API.post(APIEndpoints.UNSUBSCRIBE + `?id=${deliveryId}`);
};

const getDocumentExtractionData = async (trnxID) => {
  return await API.get(APIEndpoints.GET_DOC_EXTRACTION + `?id=${trnxID}`);
};

const updateTransactionDetails = async (payload) => {
  return await API.put(APIEndpoints.UPDATE_TRANSACTION, payload);
};

const getDocPreviewData = async (payload) => {
  return await API.post(APIEndpoints.GET_DOC_PREVIEW, `${payload}`);
};

const updateDeliveryBulkUploadStatus = async (payload) => {
  return API.put(APIEndpoints.UPDATE_BULK_DELIVERY_STATUS, null, payload);
};

const fetchBatchDetails = async (payload) => {
  return API.get(APIEndpoints.BATCH_DETAILS_LIST, null, payload);
};

const discardBatchHandler = async (param) => {
  return API.post(
    `${APIEndpoints.DISCARD_BATCH}?batchId=${param.batchId}&remarks=${param.remarks}`
  );
};

const getCSVfileDownload = async (payload) => {
  return API.get(`${APIEndpoints.GET_CSV_BASE64}?id=${payload}`);
};

const documentProcessHandler = async (param) => {
  return API.post(`${APIEndpoints.PROCESS_DOC}?batchId=${param?.batchId}`);
};

const getBatchDetails = async (params) => {
  return await API.get(APIEndpoints.BATCH_DETAILS, null, params);
};

const fetchWorkOrderDetails = async (params) => {
  return await API.get(APIEndpoints.FETCH_WORKORDER_DETAILS, null, params);
};

const assignTxnsToDelivery = async (payload) =>
  await API.post(APIEndpoints.AUTOMATE_DELIVERY, payload);

const getRecyclers = async (param) => {
  let str = `size=1000`;
  if (param.customerId) str += `&customerId=${param.customerId}`;
  return await API.get(`${APIEndpoints.GET_RECYCLERS}?${str}`);
};

const mapDeliveryToWorkOrder = async (params) => {
  return await API.put(
    APIEndpoints.MAP_DELIVERY_TO_WO + `?deliveryId=${params?.deliveryId}&woId=${params?.woId}`
  );
};

const disputeRecyclerDelivery = async (params) => {
  return await API.put(
    ApiEndpoints.SUBMIT_FOR_REVIEW + `?deliveryId=${params?.deliveryId}&status=${params?.status}`
  );
};

export const DeliveryService = {
  disputeRecyclerDelivery,
  mapDeliveryToWorkOrder,
  editAssignedQuantity,
  autoAllocation,
  fetchDeliveryById,
  fetchDeliveryByIdForSuperUser,
  fetchSourceAddresses,
  deleteDeliveryById,
  approveDeliveryByParams,
  uploadDeliveryDocument,
  updateDeliveryDocument,
  deleteDeliveryDocument,
  updateDocumentType,
  updateDelivery,
  createDelivery,
  fetchTickets,
  raiseTicket,
  replyTicket,
  fetchDeliveries,
  uploadCompressedDocument,
  checkWeighBridgeNumber,
  fetchRecyclerFromMarketPlace,
  fetchRecyclerAddresses,
  assignRecyclersToSP,
  fetchSPRecyclers,
  approveDeliveryDoc,
  rejectDocument,
  approveDocument,
  approveAllocation,
  qtySubmitForApproval,
  getAllUlbs,
  generateEwaybillEinvoice,
  generateInvoice,
  viewInvoice,
  markStatusToIntransit,
  sendPickUpspSms,
  sendDropspSms,
  doSendSMSToDriver,
  fetchEWasteBrands,
  fetchBrandDetails,
  auditDeliveryDetails,
  getUsersDetails,
  getUsersDetailsV2,
  getDocumentList,
  deletePWADeliveryById,
  fetchRequireAttentionCount,
  doBulkUpload,
  createMarketPlaceWorkOrder,
  getPVCreationRequest,
  createAggregatorPV,
  getAllDocTypes,
  updateStatus,
  updateSPDeliveryStatus,
  archiveDeliveryDocuments,
  addCertificateDate,
  generateSalvageInvoice,
  updateSalvagePrice,
  sendSubscription,
  endSubscription,
  discardBatchHandler,
  documentProcessHandler,
  updateDeliveryBulkUploadStatus,
  getDocumentExtractionData,
  updateTransactionDetails,
  getDocPreviewData,
  getBatchDetails,
  assignTxnsToDelivery,
  fetchBatchDetails,
  fetchWorkOrderDetails,
  getRecyclers,
  getCSVfileDownload,
};
