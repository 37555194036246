import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import StepperStatus from './StepperStatus';
import Tick from '../../../assets/img/SuccessCheck.svg';
import Files from '../../../assets/img/UploadFiles.svg';
import HasAuthority from '../../../shared/Components/HasAuthority/HasAuthorityNew';

const STATUS = ['UPLOADED', 'UPLOADING'];

function IndividualDeliveryStatusModal({ show, handleClose, selectedDel, discardFn, processFn }) {
  return (
    <Modal
      className="bulk-modal"
      size="lg"
      show={show}
      onHide={handleClose}
      centered
      backdrop="static">
      <div className="p-4">
        <Modal.Header closeButton className="mb-3">
          <Modal.Title>
            {selectedDel?.batchId} - {selectedDel?.serviceProviderName}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="bg-secondary rounded-3 bulk-modal--body">
          <Row>
            <Col xs={7}>
              <Form.Group as={Col}>
                <Form.Label className="label text-regular font-weight-bold mb-3">
                  Upload Document
                </Form.Label>
                <div className="d-flex">
                  <div className="uploadDocCardStyle">
                    <div>
                      <img src={Files} alt="files_upload" className="mr-2" />
                      <span>{selectedDel?.fileName}</span>
                    </div>
                    {selectedDel?.fileName && <img src={Tick} alt="green_tick" />}
                  </div>
                </div>
              </Form.Group>
            </Col>
            <Col xs={5} style={{ display: 'flex', justifyContent: 'center' }}>
              {STATUS.includes(selectedDel?.status?.toUpperCase()) ? (
                <Form.Group as={Col} className="d-flex justify-content-center align-items-center">
                  <Button
                    onClick={() => {
                      processFn(selectedDel?.batchId);
                    }}
                    style={{ display: 'block' }}
                    variant="primary"
                    className="text-center px-4">
                    Process Document
                  </Button>
                </Form.Group>
              ) : (
                <div className="d-flex justify-content-center align-items-center">
                  <span className="font-weight-bolder mt-4" style={{ fontSize: '1.05rem' }}>
                    Processing Documents...
                  </span>
                </div>
              )}
            </Col>
          </Row>
          <hr />
          <StepperStatus actionDel={selectedDel?.status} />
        </Modal.Body>

        <Modal.Footer className="mt-3">
          <HasAuthority
            permissionsAllowed={[
              { groupName: 'Delivery', moduleName: 'Bulk_Upload', name: 'can_delete' },
            ]}>
            <Button
              variant="outline-primary"
              disabled={!STATUS.includes(selectedDel?.status?.toUpperCase())}
              onClick={() => {
                discardFn(selectedDel?.batchId, 'Batch Discarded');
              }}>
              Discard Batch
            </Button>
          </HasAuthority>
          {STATUS.includes(selectedDel?.status?.toUpperCase()) && (
            <Button variant="primary" onClick={handleClose}>
              Process in Background
            </Button>
          )}
        </Modal.Footer>
      </div>
    </Modal>
  );
}

export default IndividualDeliveryStatusModal;
