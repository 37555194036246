import { createSlice } from '@reduxjs/toolkit';
import { getPermissionForRole } from './permissionsThunks';

const initialState = {
  permissions: [],
};

export const PermissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    setPermissions: (state, action) => {
      state.permissions = action.permissions;
    },
  },
  extraReducers: {
    [getPermissionForRole.pending]: (state) => {
      state.isLoading = true;
      state.error = false;
      state.status = 'loading';
    },
    [getPermissionForRole.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.permissions = action.payload.data.authority;
      state.status = action.payload.status;
      state.error = false;
    },
    [getPermissionForRole.rejected]: (state, action) => {
      state.isLoading = false;
      state.status = action.payload.status;
      state.error = true;
    },
  },
});

export const { setPermissions } = PermissionsSlice.actions;
export const permissions = (state) => state.permissions;
export default PermissionsSlice.reducer;
