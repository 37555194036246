import React from 'react';
import Api from '../shared/Config/Api';
import ApiEndpoints from '../shared/Config/ApiEndpoints.const';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { groupBy, sortBy } from 'lodash';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Button, Card, Col, Row, Spinner, Form } from 'react-bootstrap';
import './Auth/Auth.scss';
import { useAuthority } from '../shared/Components/HasAuthority/HasAuthorityNew';
import { AUTHORITY2 } from '../shared/Constants/Constants';
import logo from '../assets/img/EPR_Recykal.svg';
import loginbg from '../assets/img/recykul-login.png';
import { DropdownSelect } from '../shared/Components';
import { toCapitalize } from '../shared/Utils/Helper';
import { useAccount } from '../hooks/useAccount';
import { getAccount } from '../state/Account/accountThunks';

function SelectCustomer(props) {
  const { hasAuth } = useAuthority();
  const { user } = useAccount();
  const [customers, setCustomers] = React.useState();
  const [selectedCustomerId, setSelectedCustomerId] = React.useState();
  const [brandId, setBrandId] = React.useState();
  const [spId, setSpId] = React.useState();

  let dispatch = useDispatch();
  React.useEffect(() => {
    async function getEprCustomers() {
      const result = await Api.get(ApiEndpoints.GET_EPR_CUSTOMERS, null, {
        'eprCustomers.equals': 1,
        size: 1000,
        isDocuments: true,
      });
      let customers = result.data;
      customers = sortBy(customers, 'lastName');
      customers = groupBy(customers, 'appActor');
      setCustomers(customers);
    }
    getEprCustomers();
  }, []);

  async function go() {
    const query = { customerId: selectedCustomerId };
    const result = await Api.get(ApiEndpoints.GET_CUSTOMER_SESSION, null, query);
    localStorage.setItem('authenticate_token', result.data.id_token);
    dispatch(getAccount());
  }

  function logout() {
    localStorage.clear();
    window.location.assign('/login');
  }

  if (hasAuth([AUTHORITY2.BRAND])) {
    return <Redirect to="/work-orders" />;
  }
  if (hasAuth([AUTHORITY2.SERVICE_PROVIDER])) {
    return <Redirect to="/receivables" />;
  }

  const handleCustomer = (e, type) => {
    if (type === 'SERVICE_PROVIDER') {
      setBrandId();
      setSpId(e.id);
    } else {
      setSpId();
      setBrandId(e.id);
    }
    setSelectedCustomerId(e.id);
  };

  return (
    <PerfectScrollbar className="AuthPage p-0" style={{ height: '100vh' }}>
      <Row className="justify-content-center ml-0 mr-0">
        <Col md="6" sm="12" className="pt-4">
          <div className="mx-5 ">
            <img width="170" className="img-fluid" src={logo} alt="company_logo" />
            <div className="AuthContainer">
              <Card className="login-card mx-auto my-5 border-0">
                <Card.Header className="border-bottom-0" style={{ textAlign: 'center' }}>
                  <h5 className="heading mb-1">You logged in as {user?.firstName} !</h5>
                  <span className="heading">Select Brand/Service provider to proceed</span>
                </Card.Header>
                <Card.Body className="pt-0 animated fadeIn">
                  {customers === undefined && (
                    <div className="p-3" style={{ textAlign: 'center' }}>
                      <Spinner
                        as="span"
                        animation="border"
                        size="md"
                        role="status"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                  {customers !== undefined && (
                    <>
                      <div className="mb-3">
                        <Form.Label>{toCapitalize('BRAND')}</Form.Label>
                        <DropdownSelect
                          value={
                            brandId
                              ? {
                                  ...customers?.BRAND.find((e) => e.id === brandId),
                                }
                              : ''
                          }
                          options={[...customers?.BRAND]}
                          onChange={(e) => handleCustomer(e, 'BRAND')}
                          getOptionLabel={(option) => `${option.lastName}`}
                          getOptionValue={(option) => `${option.id}`}
                          noOptionsMessage={() => 'Brand not found!'}
                          components={{
                            IndicatorSeparator: null,
                          }}
                        />
                      </div>
                      <div>
                        <Form.Label>{toCapitalize('SERVICE_PROVIDER')}</Form.Label>
                        <DropdownSelect
                          value={
                            spId
                              ? {
                                  ...customers?.SERVICE_PROVIDER.find((e) => e.id === spId),
                                }
                              : ''
                          }
                          options={[...customers?.SERVICE_PROVIDER]}
                          onChange={(e) => handleCustomer(e, 'SERVICE_PROVIDER')}
                          getOptionLabel={(option) => `${option.lastName}`}
                          getOptionValue={(option) => `${option.id}`}
                          noOptionsMessage={() => 'Service Provider not found!'}
                          components={{
                            IndicatorSeparator: null,
                          }}
                        />
                      </div>
                    </>
                  )}

                  <Button
                    className="float-right px-4 my-4"
                    variant="primary"
                    size="lg"
                    disabled={!selectedCustomerId}
                    type="button"
                    onClick={go}>
                    GO
                  </Button>
                  <Button
                    className="float-left px-4 my-4"
                    variant="secondary"
                    size="lg"
                    type="button"
                    onClick={logout}>
                    Logout
                  </Button>
                </Card.Body>
              </Card>
            </div>
          </div>
        </Col>
        <Col md="6" className="pr-0 d-block-md">
          <div>
            <img className="login-bg" src={loginbg} alt="login_bg_image" />
          </div>
        </Col>
      </Row>
    </PerfectScrollbar>
  );
}

export default SelectCustomer;
