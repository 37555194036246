import React from 'react';
import { AUTHORITY2 } from '../../../shared/Constants/Constants';
import { useAuthority } from '../../../shared/Components/HasAuthority/HasAuthorityNew';
import { Quantity } from '../../../shared/Components';
import { useHistory } from 'react-router-dom';

export const useExecutionColumns = (view) => {
  const { hasAuth } = useAuthority();
  const history = useHistory();
  const isBrand = hasAuth([AUTHORITY2.BRAND]);

  let columns = {};

  const getQueryParams = (dataRow, fields) => {
    let currentUrlParams = new URLSearchParams();
    let obj = {
      itemIds: dataRow.data.itemId,
      stateIds: dataRow.data.stateId,
      districtIds: dataRow.data.districtId,
      ...fields,
    };
    Object.keys(obj).forEach((keyItem) => {
      if (obj[keyItem] === null || obj[keyItem] === undefined || obj[keyItem] === 0) {
        currentUrlParams.delete(keyItem);
      } else {
        currentUrlParams.set(keyItem, obj[keyItem]);
      }
    });
    return currentUrlParams;
  };
  const fixedColumnsLeft = [
    {
      dataField: 'name',
      heading: 'Material',
      sortable: true,
      styleClass: 'itemCol',
    },
  ];
  const brandColumns = [
    {
      dataField: 'orderQtyReceived',
      heading: 'Total Targets',
      sortOrder: 'desc',
      sortUsingRenderer: false,
      styleClass: 'vendor-side brandCol',
      renderer: function (dataRow, dataField) {
        return (
          <Quantity
            value={
              view === 'FY' ? dataRow.data[dataField] : dataRow.data['orderQtyDueReceived']
            }></Quantity>
        );
      },
    },
    {
      dataField: 'orderQtyGiven',
      heading: 'Targets Given',
      sortOrder: 'desc',
      sortUsingRenderer: false,
      styleClass: 'brandCol',
      renderer: function (dataRow, dataField) {
        return (
          <Quantity
            onClick={() => {
              let params = getQueryParams(dataRow, {});
              let path = `/work-orders?${params}`;
              history.push(path);
            }}
            value={
              view === 'FY' ? dataRow.data[dataField] : dataRow.data['orderQtyDueGiven']
            }></Quantity>
        );
      },
    },
    {
      dataField: 'inReviewReceived',
      heading: 'Received for Review',
      styleClass: 'brandCol',
      sortUsingRenderer: false,
      renderer: function (dataRow, dataField) {
        return (
          <Quantity
            className="text-right"
            onClick={() => {
              let params = getQueryParams(dataRow, {
                status: 'IN_REVIEW',
              });
              let path = `/deliveries?${params}`;
              history.push(path);
            }}
            value={dataRow.data[dataField]}
          />
        );
      },
    },
    {
      dataField: 'disputedReceived',
      heading: 'Disputed',
      sortUsingRenderer: false,
      styleClass: 'brandCol',
      renderer: function (dataRow, dataField) {
        return (
          <Quantity
            className="text-right"
            onClick={() => {
              let params = getQueryParams(dataRow, {
                status: 'DISPUTED',
              });
              let path = `/deliveries?${params}`;
              history.push(path);
            }}
            value={dataRow.data[dataField]}
          />
        );
      },
    },
    {
      dataField: 'acceptedReceived',
      heading: 'Targets Achieved',
      sortUsingRenderer: false,
      styleClass: 'brandCol',
      renderer: function (dataRow, dataField) {
        return (
          <Quantity
            className="text-right"
            onClick={() => {
              let params = getQueryParams(dataRow, {
                status: 'APPROVED',
              });
              let path = `/deliveries?${params}`;
              history.push(path);
            }}
            value={dataRow.data[dataField]}
          />
        );
      },
    },
    {
      dataField: 'pendingReceived',
      heading: 'Pending Fulfilment',
      sortUsingRenderer: false,
      styleClass: 'brandCol',
      renderer: function (dataRow, dataField) {
        return (
          <Quantity
            className="text-right"
            value={
              (view === 'FY' ? dataRow.data['orderQtyGiven'] : dataRow.data['orderQtyDueGiven']) -
              (dataRow.data['inReviewReceived'] +
                dataRow.data['acceptedReceived'] +
                dataRow.data['disputedReceived'])
            }
          />
        );
      },
    },
    {
      dataField: '',
      heading: 'UnAllocated Qty',
      sortUsingRenderer: false,
      styleClass: 'vendor-side brandCol',
      renderer: function (dataRow, dataField) {
        return (
          <Quantity
            className="text-right"
            value={
              view === 'FY'
                ? dataRow.data['orderQtyReceived'] - dataRow.data['orderQtyGiven']
                : dataRow.data['orderQtyDueReceived'] - dataRow.data['orderQtyDueGiven']
            }
          />
        );
      },
    },
  ];
  const spColumn = [
    {
      dataField: 'orderQtyReceived',
      heading: 'Targets Received',
      renderer: function (dataRow, dataField) {
        return (
          <Quantity
            onClick={() => {
              let params = getQueryParams(dataRow, {});
              let path = `/work-orders-incoming?${params}`;
              history.push(path);
            }}
            value={
              view === 'FY' ? dataRow.data[dataField] : dataRow.data['orderQtyDueReceived']
            }></Quantity>
        );
      },
    },
    {
      dataField: 'inReviewGiven',
      heading: 'Awaiting Client Approval',
      sortUsingRenderer: false,
      renderer: function (dataRow, dataField) {
        return (
          <Quantity
            className="text-right"
            onClick={() => {
              let params = getQueryParams(dataRow, {
                assignedDeliveryStatus: 'IN_REVIEW',
              });
              let path = `/deliveries?${params}`;
              history.push(path);
            }}
            value={dataRow.data[dataField]}
          />
        );
      },
    },
    {
      dataField: 'disputedGiven',
      heading: 'Disputed',
      sortUsingRenderer: false,
      renderer: function (dataRow, dataField) {
        return (
          <Quantity
            className="text-right"
            onClick={() => {
              let params = getQueryParams(dataRow, {
                assignedDeliveryStatus: 'DISPUTED',
              });
              let path = `/deliveries?${params}`;
              history.push(path);
            }}
            value={dataRow.data[dataField]}
          />
        );
      },
    },
    {
      dataField: 'acceptedGiven',
      heading: 'Approved by Client',
      sortUsingRenderer: false,
      renderer: function (dataRow, dataField) {
        return (
          <Quantity
            className="text-right"
            onClick={() => {
              let params = getQueryParams(dataRow, {
                assignedDeliveryStatus: 'APPROVED',
              });
              let path = `/deliveries?${params}`;
              history.push(path);
            }}
            value={dataRow.data[dataField]}
          />
        );
      },
    },
    {
      dataField: 'pendingReceived',
      heading: 'Pending Fulfilment',
      sortUsingRenderer: false,
      renderer: function (dataRow, dataField) {
        return (
          <Quantity
            className="text-right"
            value={
              (view === 'FY'
                ? dataRow.data['orderQtyReceived']
                : dataRow.data['orderQtyDueReceived']) -
              (dataRow.data['inReviewGiven'] +
                dataRow.data['acceptedGiven'] +
                dataRow.data['disputedGiven'])
            }
          />
        );
      },
    },
  ];

  const proClientColumns = [
    {
      dataField: 'orderQtyReceived',
      heading: 'Targets Received',
      sortOrder: 'desc',
      sortUsingRenderer: false,
      styleClass: 'proCol client-side',
      renderer: function (dataRow, dataField) {
        return (
          <Quantity
            onClick={() => {
              let params = getQueryParams(dataRow, {});
              let path = `/work-orders-incoming?${params}`;
              history.push(path);
            }}
            value={
              view === 'FY' ? dataRow.data[dataField] : dataRow.data['orderQtyDueReceived']
            }></Quantity>
        );
      },
    },
    {
      dataField: 'inReviewGiven',
      heading: 'Awaiting Client Approval',
      styleClass: 'proCol client-side',
      sortUsingRenderer: false,
      renderer: function (dataRow, dataField) {
        return (
          <Quantity
            className="text-right"
            onClick={() => {
              let params = getQueryParams(dataRow, {
                assignedDeliveryStatus: 'IN_REVIEW',
              });
              let path = `/deliveries?${params}`;
              history.push(path);
            }}
            value={dataRow.data[dataField]}
          />
        );
      },
    },
    {
      dataField: 'disputedGiven',
      heading: 'Disputed',
      sortUsingRenderer: false,
      styleClass: 'proCol client-side',
      renderer: function (dataRow, dataField) {
        return (
          <Quantity
            className="text-right"
            onClick={() => {
              let params = getQueryParams(dataRow, {
                assignedDeliveryStatus: 'DISPUTED',
              });
              let path = `/deliveries?${params}`;
              history.push(path);
            }}
            value={dataRow.data[dataField]}
          />
        );
      },
    },
    {
      dataField: 'acceptedGiven',
      heading: 'Approved by Client',
      sortUsingRenderer: false,
      styleClass: 'proCol client-side',
      renderer: function (dataRow, dataField) {
        return (
          <Quantity
            className="text-right"
            onClick={() => {
              let params = getQueryParams(dataRow, {
                assignedDeliveryStatus: 'APPROVED',
              });
              let path = `/deliveries?${params}`;
              history.push(path);
            }}
            value={dataRow.data[dataField]}
          />
        );
      },
    },
    {
      dataField: 'pendingReceived',
      heading: 'Pending Fulfillment',
      sortUsingRenderer: false,
      styleClass: 'proCol client-side',
      renderer: function (dataRow, dataField) {
        return (
          <Quantity
            className="text-right"
            value={
              (view === 'FY'
                ? dataRow.data['orderQtyReceived']
                : dataRow.data['orderQtyDueReceived']) -
              (dataRow.data['inReviewGiven'] +
                dataRow.data['acceptedGiven'] +
                dataRow.data['disputedGiven'])
            }
          />
        );
      },
    },
  ];
  const proVendorColumns = [
    {
      dataField: 'orderQtyGiven',
      heading: 'Target Given',
      styleClass: 'proCol vendor-side',
      renderer: function (dataRow, dataField) {
        return (
          <Quantity
            onClick={() => {
              let params = getQueryParams(dataRow, {});
              let path = `/work-orders?${params}`;
              history.push(path);
            }}
            value={
              view === 'FY' ? dataRow.data[dataField] : dataRow.data['orderQtyDueGiven']
            }></Quantity>
        );
      },
    },
    {
      dataField: 'inReviewReceived',
      heading: 'Received for Review',
      sortUsingRenderer: false,
      styleClass: 'proCol vendor-side',
      renderer: function (dataRow, dataField) {
        return (
          <Quantity
            className="text-right"
            onClick={() => {
              let params = getQueryParams(dataRow, {
                status: 'IN_REVIEW',
              });
              let path = `/deliveries?${params}`;
              history.push(path);
            }}
            value={dataRow.data[dataField]}
          />
        );
      },
    },
    {
      dataField: 'disputedReceived',
      heading: 'Disputed',
      sortUsingRenderer: false,
      styleClass: 'proCol vendor-side',
      renderer: function (dataRow, dataField) {
        return (
          <Quantity
            className="text-right"
            onClick={() => {
              let params = getQueryParams(dataRow, {
                status: 'DISPUTED',
              });
              let path = `/deliveries?${params}`;
              history.push(path);
            }}
            value={dataRow.data[dataField]}
          />
        );
      },
    },
    {
      dataField: 'acceptedReceived',
      heading: 'Accepted',
      sortUsingRenderer: false,
      styleClass: 'proCol vendor-side',
      renderer: function (dataRow, dataField) {
        return (
          <Quantity
            className="text-right"
            onClick={() => {
              let params = getQueryParams(dataRow, {
                status: 'APPROVED',
              });
              let path = `/deliveries?${params}`;
              history.push(path);
            }}
            value={dataRow.data[dataField]}
          />
        );
      },
    },
    {
      dataField: 'pendingGiven',
      heading: 'Pending Fulfillment',
      sortUsingRenderer: false,
      styleClass: 'proCol vendor-side',
      renderer: function (dataRow, dataField) {
        return (
          <Quantity
            className="text-right"
            value={
              (view === 'FY' ? dataRow.data['orderQtyGiven'] : dataRow.data['orderQtyDueGiven']) -
              (dataRow.data['inReviewReceived'] +
                dataRow.data['acceptedReceived'] +
                dataRow.data['disputedReceived'])
            }
          />
        );
      },
    },
    {
      dataField: 'selfCreatedReceived',
      heading: 'Internally Created',
      sortUsingRenderer: false,
      styleClass: 'self proCol',
      renderer: function (dataRow, dataField) {
        return <Quantity className="text-right" value={dataRow.data[dataField]} />;
      },
    },
    {
      dataField: 'unallocatedReceived',
      heading: 'Available for Allocation',
      sortUsingRenderer: false,
      styleClass: 'self proCol',
      renderer: function (dataRow, dataField) {
        return <Quantity className="text-right" value={dataRow.data[dataField]} />;
      },
    },
  ];

  columns = isBrand
    ? { clientColumns: [], vendorColumns: brandColumns }
    : { clientColumns: proClientColumns, vendorColumns: proVendorColumns };
  console.log('columns', columns);
  return {
    fixedColumnsLeft: fixedColumnsLeft,
    ...columns,
  };
};
