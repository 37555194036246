import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty, sumBy, uniq } from 'lodash';
import { Button, Card, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { RcylDatePicker } from '../../../shared/Components';
import { DropdownSelect } from '../../../shared/Components';
import { useBrowseNodeMap } from '../../../hooks/useMaterialCategory';
import { toast } from 'react-toastify';
import './PlanningModule.scss';
import { createDraftWorkOrder } from '../../../views/Planning/service';
import { useHistory } from 'react-router-dom';
import { getGstRate, setObjectToUrlParam, toServerDate } from '../../../shared/Utils/Helper';
import { FULFILLMENT_CYCLES } from '../../../shared/Constants/Constants';
import { generateMarketPlaceWorkOrder } from '../MarketPlaceCredits/service';
import { flatMap } from 'lodash';
import { useFinancialYear } from '../../../hooks/useFinancialYear';

export default function PlanningWOsCreationModal(props) {
  const {
    showModal,
    handleCloseModal,
    workOrderForm,
    isMarketPlace,
    generateWoDeliveries,
    setApiCalled,
    setCallAPI,
    handleTabSelect,
    planData,
    hasItem,
  } = props;
  const history = useHistory();
  const { itemsById } = useBrowseNodeMap();
  const [workOrderCreationLoading, setWorkOrderCreationLoading] = useState(false);
  const [materials, setMaterials] = useState([]);
  const { selectedFinancialYear } = useFinancialYear();

  const date = new Date(selectedFinancialYear?.fromDate.split('T')[0]);
  date.setDate(date.getDate() + 1);

  const browseNodes = useSelector(({ browseNodes }) => browseNodes);
  const categoryData = browseNodes?.data
    ?.find((data) => workOrderForm?.values?.clientData?.[0]?.browseNodeId === data?.id)
    ?.items?.filter((cat) => cat?.name !== 'Any');
  const itemList = flatMap(browseNodes?.data?.map((node) => node?.items));
  const totalDeliveriesQty = sumBy(generateWoDeliveries?.map((data) => data?.qty));
  const selectedVendorStateName = workOrderForm?.values?.selectedVendorData?.stateName;
  const handleKeyDown = (event) => {
    const invalidCharecters = ['-', '+', 'e', '='];
    if (invalidCharecters.includes(event?.key)) {
      event.preventDefault();
    }
  };

  const handleWorkOrderChange = (indexValue, type, value) => {
    const selectedClientData = workOrderForm?.values?.selectedClientData;

    workOrderForm.setFieldValue(
      'selectedClientData',
      selectedClientData?.map((data, rowIndex) => {
        if (rowIndex === indexValue) {
          return {
            ...data,
            toDate: type === 'fromDate' ? '' : type === 'toDate' ? value : data?.toDate,
            [type]: value,
          };
        } else return data;
      })
    );
  };

  const generateWoForMpDeliveries = async () => {
    setWorkOrderCreationLoading(true);
    const paramsClientData = workOrderForm?.values?.selectedClientData[0];
    const paramsDeliveryData = workOrderForm?.values?.selectedDeliveriesData;
    const districtIds = uniq(paramsDeliveryData.map((delivery) => delivery?.districtId));

    const params = {
      executionMode: 'SERVICE_PROVIDER',
      toCustomerId: paramsDeliveryData[0].recyclerCustomerId,
      draft: false,
      paymentTerm: Number(paramsClientData?.paymentTerm),
      poDate: paramsClientData?.poDate,
      fromDate: paramsClientData?.fromDate,
      toDate: paramsClientData?.toDate,
      workOrderItems: districtIds.map((id) => {
        const qty = sumBy(
          paramsDeliveryData
            .filter((delivery) => id === delivery?.districtId)
            .map((filteredDlvry) => filteredDlvry?.qty)
        );
        return {
          browseNodeId: itemsById[paramsDeliveryData[0].itemId]?.browseNodeId,
          itemId: paramsDeliveryData[0]?.itemId,
          stateId: paramsDeliveryData[0]?.stateId,
          districtId: id,
          pricePerUnit: Number(paramsClientData?.rate),
          qty: qty,
          estimatedDelivery: paramsClientData?.toDate,
          fulfillmentCycle: paramsClientData?.fulfillmentCycle?.value,
        };
      }),
      deliveryIds: workOrderForm?.values?.selectedDeliveriesData?.map((data) => data?.id),
    };
    const response = await generateMarketPlaceWorkOrder(params);
    if (response?.status === 201) {
      toast.success(`Work Order Generated Succesfully`);
      setApiCalled((prev) => prev + 1);
      workOrderForm.resetForm();
      handleCloseModal();
    }
  };
  useEffect(() => {
    if (isEmpty(browseNodes)) setMaterials([]);
    else {
      const materialList = [];
      const nodes = browseNodes?.data ?? [];
      nodes.forEach((items) => {
        const ITEMS = items?.items ?? [];
        ITEMS.forEach((material) => materialList.push(material));
      });
      setMaterials(materialList);
    }
  }, [browseNodes]);

  const generateWorkOrder = async () => {
    setWorkOrderCreationLoading(true);
    const payload = {
      brandWoLineItemId: workOrderForm?.values?.clientData?.find((data) => data?.isRowSelected)
        ?.workOrderItemId,
      year: date.toISOString().split('T')[0],
      workOrderList: workOrderForm?.values?.selectedClientData?.map((data) => ({
        fromDate: toServerDate(data?.fromDate),
        executionMode: data?.executionMode,
        toCustomerId: data?.serviceProviderId ? data?.serviceProviderId : data?.customerId,
        recyclerCustomerId: data?.customerId,
        toDate: toServerDate(data?.toDate),
        draft: true,
        poNumber: data?.poNumber,
        poDate: data?.poDate,
        paymentTerm: Number(data?.paymentTerm),
        status: 'IN_PROGRESS',
        documents: [],
        workOrderDocuments: [],
        traceabilityDocuments: planData?.traceabilityDocuments,
        workOrderItems: [
          {
            workOrderFor: null,
            browseNodeId: data?.browseNodeId,
            itemId: data?.itemId,
            stateId: data?.stateId,
            districtId: data?.districtId,
            pricePerUnit: Number(data?.rate),
            lineTotal: Number(data?.allocate) * Number(data?.rate),
            qty: Number(data?.allocate),
            estimatedDelivery: toServerDate(data?.toDate),
            fulfillmentCycle: data?.fulfillmentCycle?.value,
            allocationPlanQty: Number(data?.allocate),
            sac: data?.sac?.value,
            hsnCode: data?.sac?.label,
            taxRate: getGstRate(materials, data?.itemId),
          },
        ],
      })),
    };
    const response = await createDraftWorkOrder(payload);
    if (response?.status === 201) {
      toast.success(
        `Draft WO successfully updated for ${
          workOrderForm?.values?.selectedVendorData?.clientName
        } ${workOrderForm?.values?.selectedVendorData?.itemName}: ${
          workOrderForm?.values?.selectedVendorData?.stateName === undefined
            ? 'Geography Neutral'
            : workOrderForm?.values?.selectedVendorData?.stateName
        }`
      );
      handleTabSelect('Planned');
      handleCloseModal();
      setCallAPI(true);
      setObjectToUrlParam(
        {
          Brand: '123',
          brandwisePlanningTabType: 'Planned',
        },
        history
      );
    }
  };

  const checkIsFormValueEmpty = (isMarketPlace) => {
    const selectedValue = workOrderForm?.values?.selectedClientData?.map((data) => [
      isEmpty(data?.fromDate),
      isEmpty(data?.toDate),
      data?.rate,
      isEmpty(data?.fulfillmentCycle),
      !isMarketPlace && isEmpty(data?.allocate),
    ]);
    return selectedValue?.some((data) => data?.some((innerData) => innerData === true));
  };

  return (
    <div>
      <Modal show={showModal} onHide={handleCloseModal} centered size="lg">
        <Modal.Body>
          <Card className="border-0">
            <Modal.Header closeButton className="border-0">
              <Modal.Title>
                <div className="modalHeader">
                  {isMarketPlace ? (
                    <Row>
                      <Col>
                        <p style={{ marginBottom: '0px' }}>
                          MARKET PLACE : {generateWoDeliveries[0]?.item}
                          {(' ', `,`)}
                          {generateWoDeliveries[0]?.state}
                          {(' ', `,`)}
                          Quantity: {totalDeliveriesQty}KG'S
                        </p>
                      </Col>
                    </Row>
                  ) : (
                    <>
                      <p style={{ marginBottom: '0px' }}>
                        {workOrderForm?.values?.clientData?.[0]?.clientName}:{' '}
                        {categoryData?.[0]?.browseNodeTitle}
                        {' / '}
                        {
                          categoryData.find(
                            (data) =>
                              data.id === workOrderForm?.values?.selectedClientData?.[0]?.itemId
                          )?.name
                        }
                        ,{' '}
                        {selectedVendorStateName === undefined
                          ? 'Geography Neutral'
                          : selectedVendorStateName}
                      </p>
                      <div className="headerBrandNameLine">|</div>
                      <p className="woCreationStyling">WO Creation</p>
                    </>
                  )}
                </div>
              </Modal.Title>
            </Modal.Header>
            <Card.Body>
              {workOrderForm?.values?.selectedClientData?.map((workOrderCreationData, index) => {
                const itemId = itemList?.find((i) => i?.id === workOrderCreationData?.itemId);
                const hsnList = [
                  {
                    id: 1,
                    label: itemId?.goodsHsnCode?.id,
                    value: false,
                  },
                  {
                    id: 2,
                    label: itemId?.hsnCode?.id,
                    value: true,
                  },
                ];
                return (
                  <>
                    <div className="vendorName">
                      <p style={{ marginBottom: '0px' }}>
                        {(workOrderCreationData?.recyclerName
                          ? workOrderCreationData?.recyclerName + ' - '
                          : '') + workOrderCreationData?.vendorName}
                      </p>
                    </div>
                    <Row className="p-1 woDetailsRow">
                      <Form>
                        <Form.Row className="planning-form-row">
                          <Form.Group
                            as={Col}
                            className="create-date-picker-bg-img-icon mb-4 planning-form-row-large">
                            <Form.Label className="mb-0 text-medium">
                              WO Start Date <span style={{ color: 'red' }}>*</span>
                            </Form.Label>
                            <RcylDatePicker
                              inputProps={{
                                onBlur: workOrderForm.getFieldProps(
                                  `selectedClientData[${index}].fromDate`
                                ).onBlur,
                              }}
                              parentEl="#dropOffDate"
                              singleDatePicker={true}
                              controlId={'fulfillmentDateRange'}
                              placeholder="DD-MM-YYYY"
                              startDate={workOrderCreationData.fromDate}
                              onApply={({ startDate }, $event) => {
                                handleWorkOrderChange(index, 'fromDate', startDate);
                              }}
                            />
                            {workOrderForm?.touched?.selectedClientData?.[index]?.fromDate &&
                              workOrderForm?.errors?.selectedClientData?.[
                                index
                              ]?.fromDate?.trim() && (
                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                  {workOrderForm?.errors?.selectedClientData?.[index]?.fromDate}
                                </div>
                              )}
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            className="create-date-picker-bg-img-icon mb-4 planning-form-row-large">
                            <Form.Label className="mb-0 text-medium">
                              WO End Date <span style={{ color: 'red' }}>*</span>
                            </Form.Label>
                            <RcylDatePicker
                              parentEl="#dropOffDate"
                              singleDatePicker={true}
                              controlId={'fulfillmentDateRange'}
                              placeholder="DD-MM-YYYY"
                              startDate={workOrderCreationData.toDate || ''}
                              onApply={({ startDate }, $event) => {
                                handleWorkOrderChange(index, 'toDate', startDate);
                              }}
                              inputProps={{
                                onBlur: workOrderForm.getFieldProps(
                                  `selectedClientData[${index}].toDate`
                                ).onBlur,
                              }}
                              minDate={
                                isMarketPlace
                                  ? workOrderForm?.values?.selectedClientData?.[0]?.fromDate
                                  : workOrderCreationData.fromDate
                              }
                            />
                            {workOrderForm?.touched?.selectedClientData?.[index]?.toDate &&
                              !!workOrderForm?.errors?.selectedClientData?.[index]?.toDate && (
                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                  {workOrderForm?.errors?.selectedClientData?.[index]?.toDate}
                                </div>
                              )}
                          </Form.Group>
                          <Form.Group as={Col} className="mb-4 planning-form-row-small">
                            <Form.Label className="mb-0 text-medium ">
                              Rate <span style={{ color: 'red' }}>*</span>
                            </Form.Label>
                            <InputGroup>
                              <Form.Control
                                id="Rate"
                                className="p-0 float-label"
                                type="number"
                                aria-describedby="RateAppend"
                                placeholder="Enter Rate"
                                style={{
                                  backgroundColor: !isMarketPlace && '#e9ecef',
                                  textAlign: 'center',
                                }}
                                value={workOrderCreationData?.rate}
                                disabled={!isMarketPlace}
                                onChange={(event) =>
                                  handleWorkOrderChange(index, 'rate', event?.target?.value)
                                }
                                onKeyDown={handleKeyDown}
                              />
                              {!!workOrderForm?.errors?.selectedClientData?.[index]?.rate && (
                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                  {workOrderForm?.errors?.selectedClientData?.[index]?.toDate}
                                </div>
                              )}
                              <InputGroup.Append>
                                <InputGroup.Text id="RateAppend">/kg</InputGroup.Text>
                              </InputGroup.Append>
                            </InputGroup>
                          </Form.Group>

                          {!isMarketPlace && (
                            <Form.Group as={Col} className="mb-4 planning-form-row-med">
                              <Form.Label className="mb-0 text-medium">
                                Allocated Target <span style={{ color: 'red' }}>*</span>
                              </Form.Label>
                              <InputGroup>
                                <Form.Control
                                  id="allocatedQuantity"
                                  className="p-0 float-label"
                                  typitemse="number"
                                  aria-describedby="allocationAppend"
                                  style={{
                                    backgroundColor: !isMarketPlace && '#e9ecef',
                                    textAlign: 'center',
                                  }}
                                  placeholder="Allocated Quantity"
                                  value={workOrderCreationData?.allocate}
                                  disabled={!isMarketPlace}
                                  onChange={(event) =>
                                    handleWorkOrderChange(index, 'allocate', event?.target?.value)
                                  }
                                  onKeyDown={handleKeyDown}
                                />
                                <InputGroup.Append>
                                  <InputGroup.Text id="allocationAppend">KG</InputGroup.Text>
                                </InputGroup.Append>
                              </InputGroup>
                              {!!workOrderForm?.errors?.selectedClientData?.[index]?.allocate && (
                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                  {workOrderForm?.errors?.selectedClientData?.[index]?.allocate}
                                </div>
                              )}
                            </Form.Group>
                          )}
                          <Form.Group as={Col} className="mb-4 planning-form-row-med">
                            <Form.Label className="mb-0 text-medium">
                              Fulfillment Cycle <span style={{ color: 'red' }}>*</span>
                            </Form.Label>
                            <DropdownSelect
                              value={
                                workOrderCreationData?.fulfillmentCycle ??
                                workOrderForm?.fulfillmentCycle ??
                                ''
                              }
                              onChange={(fulfillmentSelectedValue) =>
                                handleWorkOrderChange(
                                  index,
                                  'fulfillmentCycle',
                                  fulfillmentSelectedValue
                                )
                              }
                              options={FULFILLMENT_CYCLES}
                              getOptionLabel={(option) => `${option.label ?? 'Select...'}`}
                              getOptionValue={(option) => `${option.id}`}
                              noOptionsMessage={() => 'Fulfillment Cycle not found!'}
                            />
                            {/* {!!workOrderForm?.errors?.selectedClientData?.[index]?.fulfillmentCycle
                              ?.value && (
                              <div className="invalid-feedback" style={{ display: 'block' }}>
                                {
                                  workOrderForm?.errors?.selectedClientData?.[index]
                                    ?.fulfillmentCycle?.value
                                }
                              </div>
                            )} */}
                          </Form.Group>
                        </Form.Row>
                        <Form.Row className="planning-form-row">
                          <Form.Group as={Col} className="mb-4 planning-form-row-large">
                            <Form.Label className="mb-0 text-medium">
                              Material <span style={{ color: 'red' }}>*</span>
                            </Form.Label>
                            <DropdownSelect
                              value={
                                categoryData && {
                                  ...categoryData.find(
                                    (data) => data.id === workOrderCreationData?.itemId
                                  ),
                                }
                              }
                              onChange={(option) =>
                                handleWorkOrderChange(index, 'itemId', option.id)
                              }
                              isDisabled={hasItem}
                              options={categoryData}
                              getOptionLabel={(option) =>
                                `${option?.name ?? 'Select Material Type'}`
                              }
                              getOptionValue={(option) => `${option.id}`}
                              noOptionsMessage={() => 'Material data not found!'}
                            />
                            {!!workOrderForm?.errors?.selectedClientData?.[index]?.itemId && (
                              <div className="invalid-feedback" style={{ display: 'block' }}>
                                {workOrderForm?.errors?.selectedClientData?.[index]?.itemId}
                              </div>
                            )}
                          </Form.Group>
                          <Form.Group as={Col} className="mb-4 planning-form-row-large">
                            <Form.Label className="mb-0 text-medium">
                              HSN/SAC Code <span style={{ color: 'red' }}>*</span>
                            </Form.Label>
                            <DropdownSelect
                              value={workOrderCreationData?.sac ? workOrderCreationData?.sac : ''}
                              onChange={(hsnSelectedValue) =>
                                handleWorkOrderChange(index, 'sac', hsnSelectedValue)
                              }
                              options={hsnList}
                              getOptionLabel={(option) => `${option.label}`}
                              getOptionValue={(option) => `${option.id}`}
                              noOptionsMessage={() => 'Options not found!'}
                            />
                            {/* {!!workOrderForm?.errors?.selectedClientData?.[index]?.sac && (
                              <div className="invalid-feedback" style={{ display: 'block' }}>
                                {workOrderForm?.errors?.selectedClientData?.[index]?.sac?.label}
                              </div>
                            )} */}
                          </Form.Group>
                          <Form.Group as={Col} className="mb-4 planning-form-row-large">
                            <Form.Label className="mb-0 text-medium">
                              PO Date(vendor) <span style={{ color: 'red' }}>*</span>
                            </Form.Label>
                            <RcylDatePicker
                              parentEl="#dropOffDate"
                              singleDatePicker={true}
                              controlId={'fulfillmentDateRange'}
                              placeholder="DD-MM-YYYY"
                              startDate={workOrderCreationData.poDate}
                              onApply={({ startDate }, $event) => {
                                handleWorkOrderChange(index, 'poDate', startDate);
                              }}
                              // minDate={workOrderForm.fromDate}
                            />
                            {workOrderForm?.errors?.selectedClientData?.[index]?.poDate && (
                              <div className="invalid-feedback" style={{ display: 'block' }}>
                                {workOrderForm?.errors?.selectedClientData?.[index]?.poDate}
                              </div>
                            )}
                          </Form.Group>
                          <Form.Group as={Col} className="mb-4 planning-form-row-large">
                            <Form.Label className="mb-0 text-medium">
                              Payment Term <span style={{ color: 'red' }}>*</span>
                            </Form.Label>
                            <Form.Control
                              {...workOrderForm.getFieldProps(
                                `selectedClientData[${index}].paymentTerm`
                              )}
                              id="paymentTerm"
                              className="p-0 float-label"
                              type="number"
                              min={1}
                              placeholder="Payment Term"
                              value={workOrderCreationData?.paymentTerm}
                              onChange={(event) =>
                                handleWorkOrderChange(index, 'paymentTerm', event?.target?.value)
                              }
                              onKeyDown={handleKeyDown}
                            />
                            {workOrderForm?.touched?.selectedClientData?.[index]?.paymentTerm &&
                              !!workOrderForm?.errors?.selectedClientData?.[index]?.paymentTerm && (
                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                  {workOrderForm?.errors?.selectedClientData?.[index]?.paymentTerm}
                                </div>
                              )}
                          </Form.Group>
                        </Form.Row>
                      </Form>
                    </Row>
                  </>
                );
              })}
            </Card.Body>
            <Card.Footer className="bg-white cardFooter">
              <div className="buttonDivStyle">
                {isMarketPlace ? (
                  <>
                    <Button className="cancelButton" variant="light" onClick={handleCloseModal}>
                      Cancel
                    </Button>
                    <Button
                      onClick={generateWoForMpDeliveries}
                      disabled={workOrderCreationLoading || checkIsFormValueEmpty(isMarketPlace)}>
                      Generate WO
                    </Button>
                  </>
                ) : (
                  <>
                    <Button className="cancelButton" variant="light" onClick={handleCloseModal}>
                      Cancel
                    </Button>
                    <Button
                      onClick={generateWorkOrder}
                      disabled={
                        workOrderCreationLoading ||
                        // checkIsFormValueEmpty() ||
                        !workOrderForm.isValid
                      }>
                      {workOrderCreationLoading
                        ? 'Sending... please wait'
                        : 'Send for Vendor Consent'}
                    </Button>
                  </>
                )}
              </div>
            </Card.Footer>
          </Card>
        </Modal.Body>
      </Modal>
    </div>
  );
}
