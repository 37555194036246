import API from '../../shared/Config/Api';
import ApiEndpoints from '../../shared/Config/ApiEndpoints.const';

const getAllPermissions = async () => {
  const response = await API.get(ApiEndpoints.GET_ALL_PERMISSIONS_FOR_APP_CODE);
  return response.data;
};

export const allPermissionsAPI = {
  getAllPermissions,
};
