import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import DocumentHistoryItem from './DocumentHistoryItem/DocumentHistoryItem';
import './DocumentHistoryContainer.scss';
import PerfectScrollbar from 'react-perfect-scrollbar';
import floaticon from '../../../assets/img/float-label.svg';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTags } from '@fortawesome/free-solid-svg-icons';
import { Mention, MentionsInput } from 'react-mentions';
import HasAuthority from '../../../shared/Components/HasAuthority/HasAuthorityNew';
import { browseNodeSelectors } from '../../../state/BrowseNode';
import { DeliveryService } from '../../Delivery/delivery.service';
import { useAsyncEffect } from '../../../hooks';
import { reloadParentDelivery } from '../../../shared/Utils/Helper';

let docsList = [];

const DocumentHistoryContainer = (props) => {
  const {
    delivery,
    displayDisputeModal,
    setDisplayDisputeModal,
    canDispute,
    isMobile,
    doFetchDelivery,
  } = props;
  const [displayConversation, setDisplayConversation] = useState(false);

  const [mentionData, setMentionData] = useState(null);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [disputeNote, setDisputeNote] = useState('');
  const [allTickets, setAllTickets] = useState([]);
  const [isMobileModal, setIsMobileModal] = useState(true);

  const fetchAllTickets = async (deliveryID) => {
    try {
      const response = await DeliveryService.fetchTickets(deliveryID);
      const ticketsArray = response.data || [];
      setAllTickets(
        ticketsArray.sort((ticket1, ticket2) => {
          return moment(ticket2.ticketDate) - moment(ticket1.ticketDate);
        })
      );
    } catch (e) {}
  };
  const [docTypeList, setDocTypeList] = useState([]);
  const browseNodes = useSelector(({ browseNodes }) => browseNodes);

  useAsyncEffect(async () => {
    const catId = browseNodeSelectors.getItemByID(browseNodes?.data, delivery.itemId)?.browseNodeId;
    if (catId !== undefined) {
      try {
        const query = catId === 3 ? `?categoryId=${catId}` : undefined;
        const response = await DeliveryService.getDocumentList(query);
        // const response = await DeliveryService.getDocumentList(`?categoryId=${catId}`);
        if (response?.status === 200) {
          setDocTypeList(response?.data);
        }
      } catch (err) {
        console.log(err);
      }
    }
  }, [browseNodes]);

  useEffect(() => {
    if (delivery.id) {
      fetchAllTickets(delivery.id);
    }
  }, [delivery.id]);

  useEffect(() => {
    if (displayDisputeModal) {
      setDisplayConversation(true);
    }
  }, [displayDisputeModal]);

  useEffect(() => {
    if (!displayConversation) {
      setDisplayDisputeModal(false);
    }
  }, [displayConversation]);

  useEffect(() => {
    if (displayConversation) {
      docsList = [];
      docTypeList.map((docList) => {
        if (docList.id === 'pickupDocs') {
          docList.documents.map((finalDocs) => {
            docsList.push({
              _id: finalDocs.type,
              name: 'Pickup ' + '' + finalDocs.title,
            });
          });
        } else if (docList.id === 'dropDocs') {
          docList.documents.map((finalDocs) => {
            docsList.push({
              _id: finalDocs.type,
              name:
                finalDocs.type === 'DROP_INWARD_TYPE'
                  ? finalDocs.title
                  : 'Drop off ' + '' + finalDocs.title,
            });
          });
        }
      });
    } else {
      setDisputeNote('');
      setDisableSubmit(false);
    }
  }, [displayConversation]);

  const disputeDelivery = async () => {
    setDisableSubmit(true);
    const ticketDetails = {
      comment: disputeNote,
      deliveryId: delivery.id,
      deliveryDocuments: [],
    };

    try {
      await DeliveryService.raiseTicket(ticketDetails);
      toast.success('Delivery disputed successfully.');
      if (isMobile) {
        doFetchDelivery();
        isMobileModal(false);
      }
      setDisplayConversation(true);
      reloadParentDelivery();
    } catch (e) {
      setDisplayConversation(false);
    }
  };

  const handleTagChange = (event, newValue, newPlainTextValue, mentions) => {
    setDisputeNote(newValue);
    setMentionData({ newValue, newPlainTextValue, mentions });
  };

  const userMentionData = docsList.map((myUser) => ({
    id: myUser._id,
    display: `${myUser.name}`,
  }));

  return (
    <>
      <div className={`document-history-container`}>
        <div className={`col-md-4 px-0 ${displayConversation ? 'expanded' : 'not-expanded'}`}>
          <div className="">
            <div className="z-100 ">
              <div className="documenthistory mb-4 px-2 ">
                {isMobileModal && (
                  <>
                    <Modal.Header
                      closeButton
                      className="border-bottom-0 pt-2 pb-0 px-5"
                      onHide={() => {
                        setDisplayConversation(false);
                      }}>
                      <Modal.Title id="example-modal-sizes-title-sm "></Modal.Title>
                    </Modal.Header>
                    <PerfectScrollbar
                      options={{ suppressScrollX: true }}
                      className="p-4"
                      style={{ height: '91vh' }}>
                      <HasAuthority
                        permissions={[
                          {
                            groupName: 'Delivery',
                            moduleName: 'Delivery',
                            name: 'dispute_delivery',
                          },
                        ]}>
                        {canDispute && displayDisputeModal ? (
                          <Row>
                            <div className="w-100 pl-4 pr-4">
                              <Form.Group className="">
                                <Form.Label>
                                  <h5 className="mb-0">Raise Dispute for this delivery</h5>
                                </Form.Label>
                                <MentionsInput
                                  value={disputeNote}
                                  onChange={handleTagChange}
                                  markup="@{{__type__||__id__||__display__}}"
                                  className="mentions"
                                  rows="3"
                                  style={{ minHeight: '100px' }}>
                                  <Mention
                                    type="user"
                                    trigger="@"
                                    data={userMentionData}
                                    className="mentions__mention"
                                    appendSpaceOnAdd={true}
                                  />
                                </MentionsInput>
                                <Form.Label className="note-text">
                                  Note : Use @ to tag document types for which you want to dispute.
                                  You can tag multiple document types.
                                </Form.Label>
                              </Form.Group>
                              <div className="float-right">
                                <Button
                                  className="ml-3 text-white mb-3"
                                  variant="danger"
                                  disabled={disputeNote === '' || disableSubmit}
                                  type="submit"
                                  onClick={disputeDelivery}>
                                  Dispute
                                </Button>
                              </div>
                            </div>
                            <Col>
                              <div className="border-dashed my-3"></div>
                            </Col>
                          </Row>
                        ) : null}
                      </HasAuthority>

                      {!isMobile && allTickets?.length > 0
                        ? allTickets.map((ticket, index) => {
                            return (
                              <DocumentHistoryItem
                                key={`TICKET-${index}`}
                                ticket={ticket}
                                delivery={delivery}
                                docTypeList={docTypeList}
                                fetchAllTickets={fetchAllTickets}
                              />
                            );
                          })
                        : !displayDisputeModal && (
                            <div className="align-items-center d-flex h-100 flex-column justify-content-center">
                              <FontAwesomeIcon
                                className="text-muted"
                                style={{ fontSize: '3rem' }}
                                icon={faTags}
                              />
                              <h6 className="mt-3">
                                There is <span className="text-danger">No Dispute</span> for this
                                delivery.{' '}
                              </h6>
                            </div>
                          )}
                    </PerfectScrollbar>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isMobile && (
        <div className="float-label-section">
          <div
            className={`float-icon ${displayConversation && 'hide-icon'}`}
            onClick={() => {
              setDisplayConversation(true);
            }}>
            <img src={floaticon} width="20" alt="float_icon" />
          </div>
        </div>
      )}
    </>
  );
};

export default DocumentHistoryContainer;
